import { Injectable, ErrorHandler, Inject, InjectionToken } from '@angular/core';
import Rollbar from 'rollbar';
import { environment } from '../../environments/environment';

const rollbarConfig = {
  accessToken: 'b131e2bd632b4f86be4b16ff1848d2f8',
  captureUncaught: true,
  captureUnhandledRejections: true,
};

export function rollbarFactory() {
  return (typeof window !== "undefined") ? new Rollbar(rollbarConfig) : null;
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable({
  providedIn: 'root'
})
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) { }

  handleError(err: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(err.message) && typeof window !== 'undefined' && window.location) {
      window.location.reload();
    } else if (err && err.length > 0 && err.indexOf("An internal error was encountered in the Indexed Database server") >= 0 && typeof window !== 'undefined' && window.location) {
      // Refresh the page to restore IndexedDb to a working state.
      window.location.reload();
    } else if (this.rollbar && environment.production) {
      this.rollbar.error(err.originalError || err);
    } else {
      console.log(err);
    }
  }
}
