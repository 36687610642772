import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { SelectableEvent } from '../models/selectable-event';
import { UtilsService } from '../services/utils.service';
import { RepetitionService } from '../services/repetition.service';
import { TranslateService } from '@ngx-translate/core';

import moment from 'moment';
import { ManualRepetitionComponent } from '../manual-repetition/manual-repetition.component';

@Component({
  selector: 'app-add-recurrence',
  templateUrl: './add-recurrence.component.html',
  styleUrls: ['./add-recurrence.component.scss'],
  providers: [
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class AddRecurrenceComponent implements OnInit {

  minDate: any;
  minEndDate: any;
  maxDate: any;
  placeholderSingle: string;

  addRecurrenceForm = new FormGroup({
    every: new FormControl('1', [Validators.max(28), Validators.min(1)]),
    timeUnit: new FormControl('week', []),
    ends: new FormControl('on', []),
    endDate: new FormControl(),
    numOccurrences: new FormControl('20', [Validators.max(165), Validators.min(1)]),
    type: new FormControl('recurrence', []),
    startTime: new FormControl('', []),
    singleDates: new FormArray([
      new FormGroup({
        startDate: new FormControl('', [Validators.required]),
        startTime: new FormControl(this.data.startTime, []),
      }, [
          ManualRepetitionComponent.differentFromEventStart(this.data.startDate, this.data.startTime, this.utilsService),
          ManualRepetitionComponent.laterThanEventStart(this.data.startDate, this.data.startTime, this.utilsService),
        ])
    ], [Validators.required, Validators.maxLength(150), this.uniqueSingle()]),
    weekDays: new FormArray([])
  });
  manualRepetitions: FormArray;

  //Week days
  monday: boolean = false;
  tuesday: boolean = false;
  wednesday: boolean = false;
  thursday: boolean = true;
  friday: boolean = false;
  saturday: boolean = false;
  sunday: boolean = false;

  hours: SelectableEvent[];


  constructor(
    public dialogRef: MatDialogRef<AddRecurrenceComponent>,
    private utilsService: UtilsService,
    private translate: TranslateService,
    private repetitionService: RepetitionService,
    private adapter: DateAdapter<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.setAdapterLocale(this.translate.currentLang);
    //Subscribe to the language change event
    this.translate.onLangChange.subscribe(() => {
      //If the language change while we are on this screen
      this.setAdapterLocale(this.translate.currentLang);
    });
    this.hours = this.utilsService.getAllHours();
    this.minDate = this.data.startDate;
    this.minEndDate = moment(this.data.startDate).add(1, 'days');
    this.addRecurrenceForm.controls['startTime'].setValue(this.data.startTime);
    let endDate = moment(this.minDate).add(6, 'weeks').toDate();
    this.maxDate = moment(this.minDate).add(1, 'year').toDate();
    this.addRecurrenceForm.controls['endDate'].setValue(endDate);
    this.placeholderSingle = moment().add(1, 'week').format("DD/MM/YYYY");
    this.manualRepetitions = this.addRecurrenceForm.get('singleDates') as FormArray;
    //Disabled by default, unless it is clicked
    this.manualRepetitions.disable();
    this.addRecurrenceForm.controls['type'].valueChanges.subscribe(
      (newValue) => {
        if (newValue == 'manual') {
          this.addRecurrenceForm.controls['every'].disable();
          this.addRecurrenceForm.controls['endDate'].disable();
          this.addRecurrenceForm.controls['numOccurrences'].disable();
          this.addRecurrenceForm.controls['singleDates'].enable();
        }
        if (newValue == 'recurrence') {
          this.addRecurrenceForm.controls['every'].enable();
          this.addRecurrenceForm.controls['endDate'].enable();
          this.addRecurrenceForm.controls['numOccurrences'].enable();
          this.addRecurrenceForm.controls['singleDates'].disable();
        }
      }
    )
  }

  onCancel() {
    this.dialogRef.close();
  }

  setAdapterLocale(language: string) {
    if (language != 'nb') {
      this.adapter.setLocale('en-GB');
    } else {
      this.adapter.setLocale(language);
    }
  }

  getWeekDays() {
    let weekDays = []
    if (this.sunday) {
      weekDays.push(0);
    }
    if (this.monday) {
      weekDays.push(1);
    }
    if (this.tuesday) {
      weekDays.push(2);
    }
    if (this.wednesday) {
      weekDays.push(3);
    }
    if (this.thursday) {
      weekDays.push(4);
    }
    if (this.friday) {
      weekDays.push(5);
    }
    if (this.saturday) {
      weekDays.push(6);
    }
    return weekDays;
  }

  fromForm() {
    let rawForm = this.addRecurrenceForm.value;
    if (rawForm.type == 'recurrence') {
      rawForm.weekDays = this.getWeekDays();
    }
    return rawForm;
  }

  addRepetitions() {
    this.addRecurrenceForm.markAllAsTouched();
    if (this.addRecurrenceForm.valid) {
      let rawForm = this.fromForm();
      let repetitions = this.repetitionService.repetitionFormToEventRepetitions(this.minDate, rawForm);
      //Add those attributes from the parent event that are not configurable on the rep form
      this.dialogRef.close(repetitions.map((rep) => ({
        ...rep,
        activeTickets: 0,
        availableTickets: this.data.availableTickets || 0,
        duration: this.data.duration,
        repId: this.utilsService.makeid(10),
        eventCancelled: this.data.eventCancelled,
        eventSoldOut: false,
        mode: this.data.mode,
        ticketsURL: this.data.ticketsURL,
        venue: this.data.venue || null,
        venueObj: this.data.venueObj || null
      })));
    }
  }

  onDateAdded() {
    this.manualRepetitions.push(new FormGroup({
      startDate: new FormControl('', [Validators.required]),
      startTime: new FormControl(this.data.startTime, []),
    }, [
        ManualRepetitionComponent.differentFromEventStart(this.data.startDate, this.data.startTime, this.utilsService),
        ManualRepetitionComponent.laterThanEventStart(this.data.startDate, this.data.startTime, this.utilsService),
      ]));
  }

  onDateRemoved(index: number) {
    this.manualRepetitions.removeAt(index);
  }

  //It validates that we have no manual repetitions repeated
  uniqueSingle(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value.length > 1) {
        const allDistinct = new Set(control.value.map(ele => ele.startDate + ele.startTime)).size == control.value.length;
        if (!allDistinct) {
          return { 'allDistinct': true };
        }
      }
      return null;
    };
  }

}
