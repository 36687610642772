import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Event } from '../models/event';
import { switchMap } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { EventService } from '../services/event.service';
import { User } from '../models/user';
import { Meta } from '@angular/platform-browser';



@Component({
  selector: 'app-event-edition',
  templateUrl: './event-edition.component.html',
  styleUrls: ['./event-edition.component.scss']
})
export class EventEditionComponent implements OnInit {

  user: User;
  event: Event;
  isEdit: boolean = false;
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    private route: ActivatedRoute,
    public authService: AuthService,
    private meta: Meta,
    private eventService: EventService) {
    this.isEdit = route.snapshot.data['isEdit'];
  }

  ngOnInit() {
    const eventId: string = this.route?.snapshot?.paramMap?.get('id') || null;
    if (eventId) {
      const unsub = this.eventService.getEventFromFirestore(eventId, (event: Event) => {
        unsub();
        this.event = event;
        this.getUserInfo();
      });
    } else {
      this.getUserInfo();
    }
    this.meta.addTag({ name: 'robots', content: 'noindex' });
  }

  getUserInfo() {
    this.authService.user.subscribe(
      user => {
        this.user = user;
        this.isLoading$.next(false);
      }
    );
  }
}
