<div class="classic" *ngIf="environment.theme == 'classic'">
  <div *ngIf="isServer || (isLoading$ | async)" class="loading">
    <mat-spinner></mat-spinner>
  </div>
  <form *ngIf="!isServer && !(isLoading$ | async)" (keydown.enter)="submitForm($event)">
    <p class="mat-body-strong" *ngIf="isRegister" translate>Introduce your email and choose a password:</p>
    <p class="mat-body-strong" *ngIf="forgot" translate>Enter your registered email address:</p>
    <mat-form-field class="full-width">
      <input cdkFocusInitial matInput #emailInput [placeholder]="'Email' | translate" [formControl]="emailFormControl">
      <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')" translate>
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="emailFormControl.hasError('required')" translate>
        Email is required
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="!forgot" class="full-width">
      <input matInput [placeholder]="'Password' | translate" [formControl]="passwordFormControl"
        [type]="hide ? 'password' : 'text'">
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
      <mat-error *ngIf="passwordFormControl.hasError('minlength')" translate>
        Password must be at least 4 chars
      </mat-error>
      <mat-error *ngIf="passwordFormControl.hasError('required')" translate>
        Password is required
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width" *ngIf="isRegister">
      <input matInput [placeholder]="'Confirm Password' | translate" [formControl]="passwordConfirmFormControl"
        [type]="hide ? 'password' : 'text'">
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
      <mat-error *ngIf="passwordConfirmFormControl.hasError('minlength')" translate>
        Password must be at least 4 chars
      </mat-error>
      <mat-error *ngIf="passwordConfirmFormControl.hasError('required')" translate>
        Password is required
      </mat-error>
    </mat-form-field>

    <div class="common-error-container mat-body-strong">
      <mat-error class="credentials-error" *ngIf="errorCode == 'auth/wrong-password'" translate>
        The password is invalid.
      </mat-error>
      <mat-error class="credentials-error" *ngIf="errorCode == 'auth/user-not-found'" translate>
        This email is not registered.
      </mat-error>
      <mat-error class="credentials-error" *ngIf="errorCode == 'auth/too-many-requests'" translate>
        Too many unsuccessful login attempts. Please try again later.
      </mat-error>
      <mat-error class="credentials-error" *ngIf="errorCode == 'auth/invalid-login-credentials'" translate>
        The password or the email are invalid.
      </mat-error>
      <mat-error class="credentials-error" *ngIf="errorCode == 'auth/weak-password'" translate>
        The password is too weak. Please try a harder one.
      </mat-error>
      <mat-error class="credentials-error" *ngIf="passMismatch" translate>
        Password mismatch. Please type it again.
      </mat-error>
      <mat-error class="credentials-error" *ngIf="errorCode == 'auth/email-already-in-use'" translate>
        This email is already registered.
      </mat-error>
      <mat-error class="credentials-error" *ngIf="errorCode == 'auth/auth/invalid-email'" translate>
        The email address is invalid. Please type it again
      </mat-error>
      <mat-error class="credentials-error" *ngIf="errorCode == 'account-exists-with-different-credential'" translate>
        An account already exists with the same email address but different sign-in credentials. Sign in using a
        provider associated with this email address.
      </mat-error>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
      <button *ngIf="!forgot && !isRegister" class="login-button" mat-raised-button fxFlex="100" color="primary"
        type="submit" (click)="login()"><span translate>Log In</span></button>
      <button *ngIf="!forgot && !isRegister" (click)="forgotAccount()" mat-stroked-button><span translate>Forgot
          password?</span></button>
      <button *ngIf="isRegister" (click)="initialScreen()" mat-stroked-button><span translate>Cancel</span></button>
      <button *ngIf="forgot" (click)="initialScreen()" mat-stroked-button><span translate>Cancel</span></button>
      <span class="fill-spacer"></span>
      <button *ngIf="!forgot && !isRegister && environment.registration.allowPublic" (click)="startRegister()"
        mat-stroked-button><span translate>Register</span></button>
      <button mat-raised-button color="primary" *ngIf="isRegister && environment.registration.allowPublic"
        (click)="register()"><span translate>Register</span></button>
      <button mat-raised-button color="primary" *ngIf="forgot" (click)="reset()"><span translate>Reset
          Password</span></button>
    </div>
  </form>

</div>

<form (keydown.enter)="submitForm($event)" class="trdevents" *ngIf="environment.theme == 'trdevents'" fxLayout="column"
  fxLayoutGap="10px">
  <div>
    <input cdkFocusInitial #emailInput [placeholder]="'Email address*' | translate" [formControl]="emailFormControl">
    <div *ngIf="emailFormControl.invalid && (emailFormControl.dirty || emailFormControl.touched)">
      <div class="error" *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')" translate>
        Please enter a valid email address
      </div>
      <div class="error" *ngIf="emailFormControl.hasError('required')" translate>
        Email is required
      </div>
    </div>
  </div>
  <div *ngIf="!forgot">
    <input [placeholder]="'Password' | translate" [formControl]="passwordFormControl" type="password">
    <div *ngIf="passwordFormControl.invalid && (passwordFormControl.dirty || passwordFormControl.touched)">
      <div class="error" *ngIf="passwordFormControl.hasError('minlength')" translate>
        Password must be at least 4 chars
      </div>
      <div class="error" *ngIf="passwordFormControl.hasError('required')" translate>
        Password is required
      </div>
    </div>
  </div>
  <div *ngIf="isRegister && environment.registration.allowPublic">
    <input [placeholder]="'Confirm Password' | translate" [formControl]="passwordConfirmFormControl" type="password">
    <div
      *ngIf="passwordConfirmFormControl.invalid && (passwordConfirmFormControl.dirty || passwordConfirmFormControl.touched)">
      <div *ngIf="passwordConfirmFormControl.hasError('minlength')" translate>
        Password must be at least 4 chars
      </div>
      <div *ngIf="passwordConfirmFormControl.hasError('required')" translate>
        Password is required
      </div>
    </div>
  </div>

  <a class="subtle" *ngIf="!forgot && !isRegister" (click)="forgotAccount()">{{'Forgot password?' | translate}}</a>

  <div class="common-error-container mat-body-strong">
    <div class="credentials-error" *ngIf="errorCode == 'auth/wrong-password'" translate>
      The password is invalid.
    </div>
    <div class="credentials-error" *ngIf="errorCode == 'auth/user-not-found'" translate>
      This email is not registered.
    </div>
    <div class="credentials-error" *ngIf="errorCode == 'auth/too-many-requests'" translate>
      Too many unsuccessful login attempts. Please try again later.
    </div>
    <div class="credentials-error" *ngIf="errorCode == 'auth/weak-password'" translate>
      The password is too weak. Please try a harder one.
    </div>
    <div class="credentials-error" *ngIf="passMismatch" translate>
      Password mismatch. Please type it again.
    </div>
    <div class="credentials-error" *ngIf="errorCode == 'auth/email-already-in-use'" translate>
      This email is already registered.
    </div>
    <div class="credentials-error" *ngIf="errorCode == 'auth/auth/invalid-email'" translate>
      The email address is invalid. Please type it again
    </div>
    <div class="credentials-error" *ngIf="errorCode == 'account-exists-with-different-credential'" translate>
      An account already exists with the same email address but different sign-in credentials. Sign in using a provider
      associated with this email address.
    </div>
  </div>

  <div class="bottom" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div *ngIf="!forgot && !isRegister" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
      <button class="login-button" type="submit" (click)="submitForm($event)">{{'Log In' | translate}}</button>
      <a class="subtle" *ngIf="environment.registration.allowPublic" (click)="startRegister()">{{'No profil? Create an
        user' | translate}}</a>
    </div>
    <div *ngIf="forgot" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <button fxFill class="secondary" (click)="initialScreen()">{{'Cancel' | translate}}</button>
      <button (click)="submitForm($event)">{{'Reset' | translate}}</button>
    </div>
    <div *ngIf="isRegister && environment.registration.allowPublic" fxLayout="column" fxLayoutAlign="center center"
      fxLayoutGap="20px">
      <button fxFill class="secondary" (click)="initialScreen()">{{'Cancel' | translate}}</button>
      <button (click)="submitForm($event)">{{'Register' | translate}}</button>
    </div>
    <div *ngIf="isRegister && environment.registration.allowPublic" class="terms-warning">
      {{'By registering an account' | translate}}, {{'I accept the' | translate}} <a routerLink="/privacy-policy">{{'terms of use' | translate}}</a>
    </div>
  </div>
</form>