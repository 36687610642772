<h2 mat-dialog-title translate>Add a venue</h2>
<form [formGroup]="addVenueForm" (keydown.enter)="preventClosing($event)">
  <div mat-dialog-content>
    <div id="adding-venue" *ngIf="formIsWorking">
      <mat-spinner diameter="60"></mat-spinner>
    </div>
    <mat-form-field appearance="outline">
      <mat-label translate>Venue name</mat-label>
      <input trim="blur" matInput #name [maxlength]="maxCharsVenue.toString()" formControlName="name">
      <mat-hint align="end">{{name.value.length}} / {{maxCharsVenue}}</mat-hint>
      <mat-error *ngIf="addVenueForm.hasError('required',['name'])" translate>
        The name is required
      </mat-error>
      <mat-error *ngIf="addVenueForm.hasError('maxlength',['name'])">
        {{'Maximum length is' | translate }} {{maxCharsVenue}} {{'chars' | translate}}
      </mat-error>
      <mat-error *ngIf="addVenueForm.hasError('validVenueName',['name'])" translate>
        There is already a venue with that name
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label translate>Address</mat-label>
      <input required matInput formControlName="address" [matAutocomplete]="auto">
      <mat-error *ngIf="addVenueForm.hasError('notInList',['address'])" translate>
        You must select a valid address
      </mat-error>
    </mat-form-field>
    <mat-autocomplete (optionSelected)="onAddressSelected($event)" [displayWith]="displayWith" classList="autocomplete-addresses" autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngIf="isLoadingAddresses" class="is-loading"><mat-spinner diameter="30"></mat-spinner></mat-option>
      <ng-container *ngIf="!isLoadingAddresses">
        <mat-option *ngFor="let address of addresses" [value]="address">{{address.description}}</mat-option>
      </ng-container>
    </mat-autocomplete>
    <div class="map" id="google-map" #gmap></div>
    <div class="instructions" translate>Drag the map to set the marker on the venue exact location</div>
  </div>
  <div align="end" mat-dialog-actions>
    <button [disabled]="formIsWorking" mat-button (click)="onCancel()">Cancel</button>
    <button [disabled]="!addVenueForm.valid || formIsWorking" mat-raised-button color="primary" type="submit" (click)="addVenue()"><span translate>Add venue</span></button>
  </div>
</form>
