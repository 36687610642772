<div fxLayout="row" [formGroup]="customerAddress"
    class="full-width" fxLayoutAlign="start start">
    <mat-form-field fxFlex="grow" appearance="outline">
        <mat-label>{{'Address' | translate}}</mat-label>
        <mat-hint>{{hint}}</mat-hint>
        <input autocomplete="off" [required]="isRequired" matInput formControlName="formattedAddress" [matAutocomplete]="auto"
            type="text">
        <mat-error *ngIf="customerAddress?.get('formattedAddress').hasError('notInList')" translate>
            You must select a valid address
        </mat-error>
        <mat-error *ngIf="customerAddress?.get('formattedAddress').hasError('required')">
            {{'You must introduce an address' | translate }}
        </mat-error>
    </mat-form-field>
    <mat-autocomplete (optionSelected)="onAddressSelected($event)" classList="autocomplete-addresses"
        autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngIf="isLoadingAddresses" class="is-loading">
            <mat-spinner diameter="30"></mat-spinner>
        </mat-option>
        <ng-container *ngIf="!isLoadingAddresses">
            <mat-option *ngFor="let address of addresses" [value]="address?.description || ''">
                {{address.description}}</mat-option>
        </ng-container>
    </mat-autocomplete>
</div>