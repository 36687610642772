import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[trim]'
})
export class TrimDirective {
  constructor(
    private elementRef: ElementRef,
    private ngControl: NgControl
  ) {}

  @HostListener('blur')
  onBlur() {
    const value = this.elementRef.nativeElement.value;
    if (typeof value === 'string') {
      const trimmed = value.trim();
      this.ngControl.control?.setValue(trimmed, {
        emitEvent: false,
        emitModelToViewChange: false,
        emitViewToModelChange: false
      });
      this.elementRef.nativeElement.value = trimmed;
    }
  }
}