import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Customer } from '../models/customer';
import { User } from '../models/user';
import { QuestionWarningComponent } from '../question-warning/question-warning.component';
import { AuthService } from '../services/auth.service';
import { CustomerService } from '../services/customer.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit, AfterViewInit, OnDestroy {

  //Auth
  user: User;
  loadingFromUserInfo: boolean = true;

  //Customers table
  displayedColumns: string[] = ['phone', 'name', 'address', 'actions'];
  dataSource: MatTableDataSource<Customer> = new MatTableDataSource<Customer>([]);;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  searchField: FormControl = new FormControl();
  allCustomers: Customer[];
  filteredCustomers: Customer[] = [];
  customers$: Observable<Customer[]>;
  fetchingCustomers: boolean = true;
  subs: Subscription;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private customerService: CustomerService,
    private titleService: Title,
    public dialog: MatDialog,
    private meta: Meta,
    public snackBar: MatSnackBar,
    public authService: AuthService,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.meta.addTag({ name: 'robots', content: 'noindex' });
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.authService.getCurrentUserInfo().pipe(first()).subscribe(
        (user: User) => {
          if (user) {
            this.user = user;
            this.loadingFromUserInfo = false;
            this.initCustomers();
            //Set the title of the page
            this.titleService.setTitle(this.translate.instant("Customers") + ' | ' + environment.content?.siteName);
            this.subs = this.searchField.valueChanges.subscribe(
              (search) => {                
                this.filterCustomers(this.allCustomers, search);
              }  
            );
          }
        }
      );
    }
  }

  initCustomers() {
    //this.customers$ = this.customerService.getAllCustomers()
    this.customerService.getAllCustomers().subscribe(
      (customers: Customer[]) => {
        this.allCustomers = customers;
        this.filterCustomers(customers);
      }
    )
  }

  filterCustomers(customers: Customer[], search?: string) {
    this.fetchingCustomers = true;
    if (search) {
      this.filteredCustomers = customers.filter((c) => c.name.toLowerCase().indexOf(search.toLowerCase()) != -1 || c.phone.toString().indexOf(search) != -1);
    } else {
      this.filteredCustomers = customers;
    }
    //Setting datasource
    if (!this.dataSource) {
      this.dataSource = new MatTableDataSource<Customer>(this.filteredCustomers);
    } else {
      this.dataSource.data = this.filteredCustomers;
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.fetchingCustomers = false;
  }

  deleteCustomer(customerId: string) {
    let dialogRef = this.dialog.open(
      QuestionWarningComponent, {
      width: '400px',
      data: {
        title: this.translate.instant("Delete customer"),
        question: this.translate.instant("Are you sure that you want to delete this customer? Bookings will not be affected.")
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.customerService.deleteCustomer(customerId)
          .then(
            () => this.snackBar.open(this.translate.instant("Your customer was successfully deleted."), null, {
              duration: 4000,
            })
          )
          .catch(
            () => this.snackBar.open(this.translate.instant("There was a problem deleting your customer. Please contact us."), null, {
              duration: 4000,
            })
          );
      }
    });

  }

}
