import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CodeProtectionService {
  private isUnlocked: boolean = false;
  private initialized: boolean = false;
  private stateChange = new Subject<boolean>();
  stateChange$ = this.stateChange.asObservable();

  constructor(private storageService: StorageService) {
    this.checkAccess();
  }

  private checkAccess(): void {
    if (!environment.protectedByCode) {
      this.isUnlocked = true;
    } else {
      const storedCode = this.storageService.get('accessCode')?.toString();
      this.isUnlocked = storedCode === environment.accessCode;
    }
    this.initialized = true;
  }

  isAccessGranted(): boolean {
    return this.isUnlocked;
  }

  isInitialized(): boolean {
    return this.initialized;
  }

  unlock(code: string): boolean {
    const isValid = code == environment.accessCode;
    if (isValid) {
      this.storageService.set('accessCode', code?.toString());
      this.isUnlocked = true;
      this.stateChange.next(true);
    }
    return isValid;
  }

  lock(): void {
    this.storageService.remove('accessCode');
    this.isUnlocked = false;
  }
}
