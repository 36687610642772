import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-customer-comments-editor',
  templateUrl: './customer-comments-editor.component.html',
  styleUrls: ['./customer-comments-editor.component.scss']
})
export class CustomerCommentsEditorComponent implements OnInit, OnChanges {

  @Input()
  comments: string;

  @Input()
  hint?: string = this.translate.instant("Let the organizer know if you have food allergies, if you are coming with wheelchair or you have any other special needs");
  
  @Input()
  toucher: Observable<any>;
  
  @Output()
  onCustomerCommentsChange: EventEmitter<string> = new EventEmitter();

  @Output()
  onValidityChange: EventEmitter<boolean> = new EventEmitter();

  customerComments: FormControl = new FormControl();
  sub: Subscription;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.customerComments.valueChanges.subscribe((newcomments) => {
      this.onCustomerCommentsChange.emit(newcomments);
      this.onValidityChange.emit(this.customerComments.valid);
    });
    this.customerComments.setValue(this.comments || '');
    this.sub = this.toucher?.subscribe(() => this.customerComments.markAllAsTouched());
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.comments?.currentValue) {
      this.customerComments.setValue(changes.comments.currentValue);
    }
  }
}