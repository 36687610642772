<div class="filter-container">

  <div class="input-container omni-box" [ngClass]="omniFocused || showPanel ? 'inverted-color' : ''" fxLayout="row">
    <input fxFlex="grow" autocomplete="off" type="search" (focus)="onOmniFocused()" (blur)="onOmniBlur()"
      [ngClass]="showPanel ? 'mat-elevation-z3 showing-panel' : ''" [formControl]="omni" matInput
      [placeholder]="'Search, filter and sort events...' | translate" #omniInput>
    <button class="clear" *ngIf="omniInput.value?.length > 0" (click)="clear()" mat-icon-button>
      <mat-icon fxHide.lt-md fxShow.gt-sm>clear</mat-icon>
      <mat-icon fxHide.gt-sm fxShow.lt-md>highlight_off</mat-icon>
    </button>
    <button class="expand" *ngIf="!showPanel" (click)="showPanel = true" mat-icon-button>
      <mat-icon fxHide.lt-md fxShow.gt-sm>expand_more</mat-icon>
      <mat-icon fxHide.gt-sm fxShow.lt-md>arrow_drop_down_circle</mat-icon>
    </button>
    <button class="expand" *ngIf="showPanel" (click)="showPanel = false" mat-icon-button>
      <mat-icon fxHide.lt-md fxShow.gt-sm>expand_less</mat-icon>
      <mat-icon fxHide.gt-sm fxShow.lt-md>arrow_drop_up_circle</mat-icon>
    </button>
  </div>

  <div *ngIf="showPanel" class="filter-panel mat-elevation-z3">
    <form [formGroup]="filterForm" class="full-width" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px">

      <div fxFlex="100">
        <div class="label">{{'Filtering and sorting' | translate}}</div>
      </div>

      <div fxFlex="300px" fxLayout="row">
        <mat-form-field fxFlex="300px" appearance="outline">
          <mat-label>{{'Categories' | translate}}</mat-label>
          <mat-select formControlName="categories" multiple>
            <mat-option *ngFor="let category of allCategories" [value]="category.id">{{category.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex="300px" fxLayout="row">
        <mat-form-field fxFlex="300px" appearance="outline">
          <mat-label translate>From date</mat-label>
          <input (click)="picker.open()" autocomplete="off" matInput [matDatepicker]="picker"
            [placeholder]="'From today' | translate" [min]="minDate" formControlName="fromDate">
          <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon>event</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div fxFlex="300px" fxLayout="row">
        <mat-form-field fxFlex="300px" appearance="outline">
          <mat-label>{{'Organizer' | translate}}</mat-label>
          <mat-select formControlName="organizer">
            <mat-option *ngFor="let organizer of organizers | async" [value]="organizer.slug">{{organizer.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex="300px" fxLayout="row">
        <mat-form-field fxFlex="300px" appearance="outline">
          <mat-label>{{'Venue' | translate}}</mat-label>
          <mat-select formControlName="venue">
            <mat-option *ngFor="let venue of venues | async" [value]="venue.slug">{{venue.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex="300px" fxLayout="row">
        <mat-form-field fxFlex="300px" appearance="outline">
          <mat-label>{{'Umbrella event' | translate}}</mat-label>
          <mat-select formControlName="superEvent">
            <mat-option *ngFor="let superEvent of allSuperEvents | async" [value]="superEvent.id">{{superEvent.title_nb}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex="300px" fxLayout="row">
        <mat-form-field fxFlex="300px" appearance="outline">
          <mat-label>{{'Mode' | translate}}</mat-label>
          <mat-select formControlName="mode" value="all">
            <mat-option value="all">{{'All' | translate}}</mat-option>
            <mat-option value="online">{{'Only online' | translate}}</mat-option>
            <mat-option value="offline">{{'Offline' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex="300px" fxLayout="row">
        <mat-form-field fxFlex="300px" appearance="outline">
          <mat-label>{{'Tag' | translate}}</mat-label>
          <input matInput formControlName="tag" type="text" />
        </mat-form-field>
      </div>

      <div fxFlex="300px" fxLayout="row">
        <mat-form-field fxFlex="300px" appearance="outline">
          <mat-label>{{'Sorting' | translate}}</mat-label>
          <mat-select formControlName="sortBy">
            <mat-option value="popularity">{{'By popularity' | translate}}</mat-option>
            <mat-option value="date">{{'By date' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex="100" fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="end center">
        <button mat-button type="button" (click)="resetFilters()">
          {{ 'Reset' | translate }}
        </button>
        <button mat-button color="primary" type="button" (click)="showPanel = false">
          {{ 'OK' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>