import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';

import { environment } from '../../environments/environment';

import moment from 'moment';
import { isPlatformBrowser } from '@angular/common';
import { UtilsService } from '../services/utils.service';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CategoryService } from '../services/category.service';
import { Category } from '../models/category';
import { filter, map } from 'rxjs/operators';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Output()
  toggle = new EventEmitter();

  @Input()
  openedMenu: boolean = false;

  environment: any;
  demo: boolean = false;
  contrast: boolean = false;
  sub: Subscription;
  routerEvents: Subscription;
  superCategories: Category[];

  isVMFest: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    public translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: any,
    private storageService: StorageService,
    public authService: AuthService,
    private utilsService: UtilsService) {
    this.environment = environment;
  }
  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.routerEvents?.unsubscribe();
  }

  ngOnInit() {
    //Set in case of demo
    this.demo = this.environment.demo;
    if (this.environment.theme == 'trdevents' && isPlatformBrowser(this.platformId)) {
      this.loadCategories();
      this.setupURLVMFestListener();
    }
  }

  setupURLVMFestListener() {
    this.routerEvents =  this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let route = this.route;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      map(route => route.snapshot.data)
    )
    .subscribe(data => {
      this.isVMFest = data?.custom === 'vm-fest';
    });
  }

  switchLanguage(language: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(language);
      this.authService.changeLanguage(language);
      if (this.utilsService.storageAvailable()) {
        this.storageService.set('lan', language);
      }
      //Setting the locale for momentjs
      this.setMomentLocale(language);
      if (this.environment.theme == 'trdevents') {
        this.loadCategories();
      }
    }
  }

  setMomentLocale(language: string) {
    if (language != 'nb') {
      moment.locale('en-GB');
    } else {
      moment.locale(language);
    }
  }

  toggleDrawer() {
    this.toggle.emit();
  }

  loadCategories() {
    this.superCategories = this.categoryService.getAllCategories().filter(c => c.id != 'OTHER');
    this.sub = this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          this.contrast = this.router.url.indexOf('/organizers') != -1;
        }
      }
    );
  }

}
