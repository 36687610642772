<div class="label">{{'Actions' | translate}}</div>
<div *ngIf="booking?.actions[booking.actions.length - 1].status == 'success' || !booking" class="margin-bottom">
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="10px" *ngIf="action == null">
        <button mat-stroked-button [matMenuTriggerFor]="changemenu">{{'Change' | translate}}... <mat-icon>
                arrow_drop_down</mat-icon></button>
        <mat-menu #changemenu="matMenu">
            <button *ngIf="booking && canAction('change-tickets')" mat-menu-item (click)="showAction('change-tickets')">
                <mat-icon>local_activity</mat-icon> {{'Tickets' | translate}}
            </button>
            <button *ngIf="booking" mat-menu-item (click)="showAction('change-customer-name')">
                <mat-icon>person</mat-icon> {{'Name' | translate}}
            </button>
            <button mat-menu-item *ngIf="booking"
                (click)="showAction('change-customer-address')">
                <mat-icon>house</mat-icon> {{'Address' | translate}}
            </button>
            <button mat-menu-item *ngIf="canAction('change-customer-phone') && booking"
                (click)="showAction('change-customer-phone')">
                <mat-icon>phone</mat-icon> {{'Phone' | translate}}
            </button>
            <button *ngIf="booking" mat-menu-item (click)="showAction('change-customer-comments')">
                <mat-icon>description</mat-icon> {{'Comments' | translate}}
            </button>
            <button *ngIf="canAction('admin-change-status')" mat-menu-item (click)="showAction('admin-change-status')">
                <mat-icon>check_box</mat-icon> {{'Status' | translate}}
            </button>
            <button *ngIf="canAction('admin-change-venue')" mat-menu-item (click)="showAction('admin-change-venue')">
                <mat-icon>map</mat-icon> {{'Event venue' | translate}}
            </button>
            <button *ngIf="canAction('admin-change-start-date')" mat-menu-item
                (click)="showAction('admin-change-start-date')">
                <mat-icon>event</mat-icon> {{'Event start date' | translate}}
            </button>
        </mat-menu>
        <button mat-stroked-button
            *ngIf="(bookings?.length > 0 || booking?.customerPhone) && canAction('admin-send-sms')"
            (click)="showAction('admin-send-sms')">
            <mat-icon>sms</mat-icon> {{'Send an SMS' | translate }}
        </button>
        <button *ngIf="booking && isAdmin && canAction('admin-cancel-booking')" mat-stroked-button
            (click)="showAction('admin-cancel-booking')" color="warn">
            <mat-icon>event_busy</mat-icon> {{'cancel_booking' | translate }}
        </button>
        <button *ngIf="booking && !isAdmin && canAction('guest-cancel-booking')" mat-stroked-button
            (click)="showAction('guest-cancel-booking')" color="warn">
            <mat-icon>event_busy</mat-icon> {{'cancel_booking' | translate }}
        </button>
    </div>
    <form fxLayout="column" class="full-width" fxLayoutGap="10px" *ngIf="action != null">

        <div *ngIf="action == 'admin-send-sms'">
            <app-customized-sms [isAdmin]="isAdmin" [action]="'admin-send-sms'"
                (smsContentChange)="onSMSContentChange($event)" (onValidityChange)="validComponents[0] = $event"
                [numberSMS]="bookings?.length || 1"></app-customized-sms>
        </div>

        <div *ngIf="action == 'admin-cancel-booking' && isAdmin" fxLayout="column" fxLayoutGap="15px">
            <app-customized-sms [isAdmin]="isAdmin" [numberSMS]="bookings?.length || 1"
                [action]="'admin-cancel-booking'" (smsContentChange)="onSMSContentChange($event)"
                (sendSmsChange)="onSendSmsChange($event)"
                (onValidityChange)="validComponents[0] = $event"></app-customized-sms>
        </div>

        <div *ngIf="action == 'guest-cancel-booking' && !isAdmin" fxLayout="column" fxLayoutGap="15px">
            <div>{{"This action will cancel all your tickets. Do you want to continue?" | translate}}</div>
            <app-customized-sms [isAdmin]="isAdmin" [numberSMS]="bookings?.length || 1"
                [action]="'guest-cancel-booking'" (smsContentChange)="onSMSContentChange($event)"
                (sendSmsChange)="onSendSmsChange($event)"
                (onValidityChange)="validComponents[0] = $event"></app-customized-sms>
        </div>

        <div *ngIf="action == 'change-tickets'" fxLayout="column" fxLayoutGap="15px">
            <app-ticket-selector [booking]="booking" (onTicketsChange)="onTicketsChange($event)"
                (onValidityChange)="validComponents[1] = $event"></app-ticket-selector>
            <app-customized-sms [isAdmin]="isAdmin" [action]="'change-tickets'"
                (smsContentChange)="onSMSContentChange($event)" (onValidityChange)="validComponents[0] = $event"
                (sendSmsChange)="onSendSmsChange($event)" [numberSMS]="bookings?.length || 1"
                [status]="bookingStatus"></app-customized-sms>
        </div>

        <div *ngIf="action == 'change-customer-name'" fxLayout="column" fxLayoutGap="15px">
            <app-customer-name-editor [name]="booking.customerName || null"
                (onNameChange)="onCustomerNameChange($event)"
                (onValidityChange)="validComponents[1] = $event"></app-customer-name-editor>
            <app-customized-sms [isAdmin]="isAdmin" [action]="'change-customer-name'"
                (smsContentChange)="onSMSContentChange($event)" (onValidityChange)="validComponents[0] = $event"
                (sendSmsChange)="onSendSmsChange($event)" [numberSMS]="bookings?.length || 1"></app-customized-sms>
        </div>

        <div *ngIf="action == 'change-customer-address'" fxLayout="column" fxLayoutGap="15px">
            <app-customer-address-editor [address]="booking.customerAddress || null"
                (onCustomerAddressChange)="onCustomerAddressChange($event)"
                (onValidityChange)="validComponents[1] = $event"></app-customer-address-editor>
            <app-customized-sms [isAdmin]="isAdmin" [action]="'change-customer-address'"
                (smsContentChange)="onSMSContentChange($event)" (onValidityChange)="validComponents[0] = $event"
                (sendSmsChange)="onSendSmsChange($event)" [numberSMS]="bookings?.length || 1"></app-customized-sms>
        </div>

        <div *ngIf="action == 'change-customer-phone'" fxLayout="column" fxLayoutGap="15px">
            <app-customer-phone-editor [phone]="booking.customerPhone || null"
                (onPhoneChange)="onCustomerPhoneChange($event)"
                [hint]="'The system will attempt to deliver the last failed SMS' | translate"
                (onValidityChange)="validComponents[1] = $event"></app-customer-phone-editor>
        </div>

        <div *ngIf="action == 'change-customer-comments'" fxLayout="column" fxLayoutGap="15px">
            <app-customer-comments-editor [comments]="booking.comments || null"
                (onCustomerCommentsChange)="onCustomerCommentsChange($event)"
                (onValidityChange)="validComponents[1] = $event"></app-customer-comments-editor>
            <app-customized-sms [isAdmin]="isAdmin" [action]="'change-customer-comments'"
                (smsContentChange)="onSMSContentChange($event)" (onValidityChange)="validComponents[0] = $event"
                (sendSmsChange)="onSendSmsChange($event)" [numberSMS]="bookings?.length || 1"></app-customized-sms>
        </div>

        <div *ngIf="action == 'admin-change-status'" fxLayout="column" fxLayoutGap="15px">
            <app-booking-status-editor [status]="booking?.status" (onStatusChange)="onStatusChange($event)"
                (onValidityChange)="validComponents[1] = $event"></app-booking-status-editor>
            <app-customized-sms [isAdmin]="isAdmin" [action]="'admin-change-status'"
                (smsContentChange)="onSMSContentChange($event)" (onValidityChange)="validComponents[0] = $event"
                (sendSmsChange)="onSendSmsChange($event)" [numberSMS]="bookings?.length || 1"
                [status]="bookingStatus"></app-customized-sms>
        </div>

        <div *ngIf="action == 'admin-change-venue'" fxLayout="column" fxLayoutGap="15px">
            <app-booking-venue-editor [booking]="booking" (onVenueChange)="onVenueChange($event)"
                (onValidityChange)="validComponents[1] = $event"></app-booking-venue-editor>
            <app-customized-sms [isAdmin]="isAdmin" [action]="'admin-change-venue'"
                (smsContentChange)="onSMSContentChange($event)" (onValidityChange)="validComponents[0] = $event"
                (sendSmsChange)="onSendSmsChange($event)" [numberSMS]="bookings?.length || 1"></app-customized-sms>
        </div>

        <div *ngIf="action == 'admin-change-start-date'" fxLayout="column" fxLayoutGap="15px">
            <app-booking-start-date-editor [booking]="booking" (onStartDateChange)="onStartDateChange($event)"
                (onValidityChange)="validComponents[1] = $event"></app-booking-start-date-editor>
            <app-customized-sms [isAdmin]="isAdmin" [action]="'admin-change-start-date'"
                (smsContentChange)="onSMSContentChange($event)" (onValidityChange)="validComponents[0] = $event"
                (sendSmsChange)="onSendSmsChange($event)" [numberSMS]="bookings?.length || 1"></app-customized-sms>
        </div>

        <div fxLayout="row" fxLayoutGap="10px">
            <button mat-stroked-button (click)="action = null">{{'Cancel' | translate }}</button>
            <button type="submit" mat-flat-button (click)="executeAction()" color="primary">{{'Confirm' | translate
                }}</button>
        </div>

    </form>
</div>
<div class="margin-bottom" *ngIf="booking?.actions[booking.actions.length - 1].status == 'executing' || booking?.actions[booking.actions.length - 1].status == 'waiting-sms-response'">
        {{'Please wait until the last action is successfully executed' | translate}}.
</div>
<div class="margin-bottom" *ngIf="booking?.actions[booking.actions.length - 1].status == 'error'">
        {{'There has been an error with your booking. Please contact us.' | translate}}.
</div>