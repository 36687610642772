import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Booking, Ticket } from '../models/booking';
import { formatDate } from '@angular/common';
import { BookingStatusService } from '../services/booking-status.service';
import { TicketTypeService } from '../services/ticket-type.service';
import { BookingService } from '../services/booking.service';
import { EventService } from '../services/event.service';
import { Event } from '../models/event';
import { UtilsService } from '../services/utils.service';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-booking-guest-editor',
  templateUrl: './booking-guest-editor.component.html',
  styleUrls: ['./booking-guest-editor.component.scss']
})
export class BookingGuestEditorComponent implements OnInit, OnChanges {

  @Input()
  bookings: Booking[];

  selectedBooking: Booking | null = null;
  maxCancellationDate: string;
  showPastBookings = false;
  showPastEvents: boolean = false;
  environment = environment;  // Make environment available to template
  cancellationDateIsOver: boolean = false;

  constructor(
    private utilsService: UtilsService,
    private bookingService: BookingService,
    private eventService: EventService,
    private bookingStatusService: BookingStatusService,
    private ticketTypeService: TicketTypeService) { }

  ngOnInit(): void {
    if (this.bookings?.length) {
      this.selectBooking(this.bookings[0]);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bookings) {
      // Handle case when bookings array is initialized or completely changed
      if (changes.bookings.currentValue) {
        const newBookings = changes.bookings.currentValue;

        // If we have a selected booking, check if it still exists in new array
        if (this.selectedBooking) {
          const stillExists = newBookings.some(b => b.id === this.selectedBooking?.id);
          if (!stillExists) {
            this.selectedBooking = null;
          } else {
            // Update the selected booking with new data
            const updatedBooking = newBookings.find(b => b.id === this.selectedBooking?.id);
            if (updatedBooking) {
              this.selectedBooking = updatedBooking;
              this.setupDeadlineForCancellation();
            }
          }
        }

        // If no booking is selected and we have bookings, select the first one
        // (but only on first load, not on every change)
        if (!this.selectedBooking && changes.bookings.firstChange && newBookings.length > 0) {
          this.selectBooking(newBookings[0]);
        }
      } else {
        // Bookings array is empty or null
        this.selectedBooking = null;
        this.maxCancellationDate = null;
      }
    }
  }

  get filteredBookings(): Booking[] {
    const now = new Date().getTime() / 1000; // Convert to seconds
    return this.bookings?.filter(booking => {
      const isUpcoming = booking.startDate?.seconds > now;
      return this.showPastBookings ? !isUpcoming : isUpcoming;
    })
      .sort((a, b) => {
        // For upcoming: earliest first (ascending)
        // For past: latest first (descending)
        return this.showPastBookings
          ? (b.startDate?.seconds || 0) - (a.startDate?.seconds || 0)
          : (a.startDate?.seconds || 0) - (b.startDate?.seconds || 0);
      }) || [];
  }

  togglePastBookings(): void {
    this.showPastBookings = !this.showPastBookings;
    this.selectedBooking = null;
  }

  selectBooking(booking: Booking): void {
    this.selectedBooking = this.selectedBooking?.id === booking.id ? null : booking;
    if (this.selectedBooking) {
      this.setupDeadlineForCancellation();
      this.listenOnBookingChanges();
    }
  }

  listenOnBookingChanges() {
    if (!this.selectedBooking) return;
    this.bookingService.getBooking(this.selectedBooking.id)
      .then((updatedBooking: Booking) => {
        this.selectedBooking = updatedBooking;
        // Update the booking in the list as well
        const index = this.bookings.findIndex(b => b.id === updatedBooking.id);
        if (index !== -1) {
          this.bookings[index] = updatedBooking;
        }
      });
  }

  setupDeadlineForCancellation() {
    if (!this.selectedBooking?.eventId || !this.selectedBooking?.startDate?.seconds) {
      console.log("Missing required booking data", this.selectedBooking);
      return;
    }

    const sub = this.eventService.getEventFromFirestore(this.selectedBooking.eventId, (event: Event) => {
      sub();
      const bookingStartDateInSeconds = this.selectedBooking?.startDate?.seconds || null;
      // Verificar que aún tenemos un booking seleccionado y sus datos
      if (!bookingStartDateInSeconds) {
        console.log("Booking data no longer available");
        return;
      }

      // Check if we have got an event
      if (!event) {
        console.log("Event data not available or invalid cancellation period", event);
        this.maxCancellationDate = null;
        this.cancellationDateIsOver = false;
        return;
      }

      try {
        const deadlineTimestamp = bookingStartDateInSeconds -
          (event.cancellationPeriod * 24 * 60 * 60);
        const deadlineDate = new Date(deadlineTimestamp * 1000);

        if (isNaN(deadlineDate.getTime())) {
          console.error("Invalid date calculation", {
            startDate: this.selectedBooking.startDate,
            cancellationPeriod: event.cancellationPeriod,
            deadlineTimestamp
          });
          return;
        }
        // Set the time to 15:00 (3:00 PM)
        deadlineDate.setHours(15, 0, 0, 0);
        this.maxCancellationDate = formatDate(deadlineDate, "short", 'nb-NO');
        this.cancellationDateIsOver = deadlineTimestamp < new Date().getTime() / 1000;
      } catch (error) {
        console.error("Error calculating deadline", error);
      }
    });
  }

  getDateTime(seconds: number): string {
    return formatDate(new Date(seconds * 1000), "short", 'nb-NO');
  }

  getStatus(status: string): string {
    return this.bookingStatusService.getBookingStatusName(status);
  }

  getTicketType(ticket: Ticket): string {
    return this.ticketTypeService.getTicketTypeName(ticket);
  }

  trim(myStr?: string, numChats?: number): string {
    return this.utilsService.trimWithMax(myStr || '', numChats || 70);
  }

}
