import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Event } from '../models/event';

import { environment } from '../../environments/environment';
import moment from 'moment';
import { UtilsService } from './utils.service';


@Injectable()
export class SeoService {

  private environment = environment;

  constructor(private meta: Meta,
    private utilsService: UtilsService
  ) {
    this.environment = environment;
  }

  generateTags(config) {
    // default values
    config = {
      title: environment.content.siteName,
      description: environment.ui.showWelcomeMessage ? environment.content.welcomeMessage.nb : environment.content.siteName,
      image: environment.siteURL + '/assets/pictures/' + environment.placeholderImage,
      imageWidth: 900,
      imageHeight: 200,
      slug: '',
      fbAppID: environment.facebookAppID,
      admins: 'pablo.margareto',
      ...config
    }
    //Google
    this.meta.updateTag({ property: 'description', content: config.description });

    //Twitter
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: environment.content.siteName });
    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({ name: 'twitter:description', content: config.description });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

    //Facebook
    this.meta.updateTag({ property: 'og:type', content: "website" });
    this.meta.updateTag({ property: 'fb:app_id', content: config.fbAppID });
    this.meta.updateTag({ property: 'fb:admins', content: config.admins });
    this.meta.updateTag({ property: 'og:site_name', content: environment.content.siteName });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:image:alt', content: config.imageAlt });
    this.meta.updateTag({ property: 'og:image:width', content: config.imageWidth });
    this.meta.updateTag({ property: 'og:image:height', content: config.imageHeight });
    this.meta.updateTag({ property: 'og:url', content: environment.siteURL + `/${config.slug}` });
  }


  getEventSchema(event: Event, superEvent?: Event, subEvents?: Event[]): {} {
    let eventSchema = {
      "@context": "https://schema.org",
      "@type": "Event",
      "name": this.utilsService.getTitle(event),
      "startDate": moment(event.startDate.seconds * 1000).format(),
      "endDate": moment(event.endDate.seconds * 1000).format(),
      "image": (event.images.length > 0 ? event.images.map(image => image.urlLarge) : []),
      "description": this.utilsService.getDesc(event),
      "eventAttendanceMode": event.mode == "online" ? "https://schema.org/OnlineEventAttendanceMode" : "https://schema.org/OnlineEventAttendanceMode",
      "eventStatus": event.eventCancelled ? "https://schema.org/EventCancelled" : "https://schema.org/EventScheduled",
    };
    if (event.mode == "offline" && event.venue) {
      eventSchema["location"] = {
        "@type": "Place",
        "name": event.venue.name,
        "address": {
          "@type": "PostalAddress",
          "streetAddress": event.venue?.address || this.venueAddress(event.venue.address),
          "addressLocality": event.venue?.municipality || this.venueLocation(event.venue.address),
          "addressCountry": "NO"
        }
      }
    }
    if (event.mode == "online") {
      eventSchema["location"] = {
        "@type": "VirtualLocation",
        "url": event.streamingURL || event.moreInfoURL || environment.siteURL + '/event/' + event.event_slug,
      }
    }
    if (event.organizers && event.organizers.length > 0) {
      eventSchema["organizer"] = {
        "@type": "Organization",
        "name": event.organizers[0]?.name || event.organizers[0].organizerObj?.name,
        "url": event.organizers[0]?.website || event.organizers[0].organizerObj?.website || this.environment.siteURL + '/organizer/' + (event.organizers[0].organizer?.slug || event.organizers[0].organizerObj?.slug || ''),
        "telephone": event.organizers[0]?.telephoneNumber || event.organizers[0].organizerObj?.telephoneNumber || '',
        "email": event.organizers[0]?.email || event.organizers[0].organizerObj?.email || ''
      }
    }
    if (event.maximumAge) {
      eventSchema["typicalAgeRange"] = event.maximumAge + "-";
    }
    if (event.minimumAge) {
      eventSchema["typicalAgeRange"] = event.minimumAge + "+";
    }
    if (superEvent) {
      eventSchema["superEvent"] = this.getEventSchema(superEvent);
    }
    if (subEvents) {
      eventSchema["subEvents"] = subEvents.map(event => this.getEventSchema(event));
    }
    return eventSchema;
  }

  //TODO Change for the right ones, when the addresses be formatted
  venueAddress(venueAddress: string): string {
    if (venueAddress != null) {
      return venueAddress.replace(", Norway", "").replace(", Norge", "").split(",")[0];
    }
    return '';
  }

  venueLocation(venueAddress: string): string {
    if (venueAddress != null) {
      return venueAddress.replace(", Norway", "").replace(", Norge", "").split(",")[1];
    }
    return '';
  }

}
