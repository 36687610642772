<div fxLayout="column" *ngIf="showProperty" fxLayoutGap="15px" class="property full-width">
  <mat-divider></mat-divider>
  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
    <div fxFlex="20px" fxLayout="row" fxLayoutAlign="start center">
      <img class="icon" [src]="iconSrc" />
    </div>
    <div fxFlex="grow" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
      <div class="title full-width">{{title}}</div>
      <div *ngIf="property === 'share'" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <a [href]="'https://www.facebook.com/dialog/share?app_id=' + environment.facebookAppID + '&display=popup&href=' + event.eventLink " target="_blank">{{'Facebook' | translate}}</a>
        <a [href]="'https://twitter.com/intent/tweet?text='+ event.title_nb +'...&url=' + event.eventLink" target="_blank">{{'Twitter' | translate}}</a>
      </div>
      <div *ngIf="property === 'add-to-my-calendar'" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <a (click)="generateICS()">ICS</a>
        <a [href]="getLinkForGoogleCalendar()" target="_blank">{{'Google Calendar' | translate}}</a>
      </div>
      <div class="body full-width" *ngIf="body.length > 0" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
        <div *ngFor="let paragraph of body" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px" class="full-width">
          <div *ngIf="paragraph.name" class="name">{{paragraph.name}}</div>
          <div *ngIf="paragraph.note" class="note">{{paragraph.note}}</div>
          <img *ngIf="mapImage" class="map full-width" [src]="mapImage" />
          <div *ngIf="paragraph.address" class="address">
            <div>{{paragraph.address}}</div>
            <div *ngIf="paragraph.municipality">{{paragraph.municipality}}</div>
          </div>
          <div *ngIf="paragraph.callToActions?.length > 0">
            <div class="call-to-action" *ngFor="let cta of paragraph.callToActions">
              <div *ngIf="cta.link">
                <div *ngIf="cta.isExternal"><a [href]="cta.link" target="_blank">{{cta.label}}</a> <img class="external-link" src="assets/icons/open_in_new.svg" /></div>
                <div *ngIf="!cta.isExternal"><a (click)="scrollToAnchor(cta.fragment || null)" [routerLink]="cta.link" [fragment]="cta.fragment">{{cta.label}}</a></div>
              </div>
              <div *ngIf="!cta.link">
                <div>{{cta.label}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
