import { isPlatformBrowser } from '@angular/common';
import { Event } from '../models/event';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ScrollService } from '../services/scroll.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FavoritesService } from '../services/favorites.service';

@Component({
  selector: 'app-my-favorites',
  templateUrl: './my-favorites.component.html',
  styleUrls: ['./my-favorites.component.scss']
})
export class MyFavoritesComponent implements OnInit, OnDestroy {

  moveToPreviousScrollPosition: boolean = true;
  favoritesList: Event[] = [];
  favoritesListSubs: Subscription;
  totalHits: number = 0;
  loading: boolean = true;
  environment: any;

  constructor(
    private favoritesService: FavoritesService,
    @Inject(PLATFORM_ID) private platformId: any,
    private scrollService: ScrollService) { }

  ngOnInit(): void {
    this.environment = environment;
    this.initListSubscription();
  }

  ngOnDestroy(): void {
    this.favoritesListSubs?.unsubscribe();
  }

  lastEventIsRendered() {
    if (isPlatformBrowser(this.platformId)) {
      if (typeof window !== 'undefined'
        && this.scrollService.getPreviousUrl()?.indexOf('/event/') != -1
        && this.moveToPreviousScrollPosition) {
        window.scrollTo({ left: 0, top: this.scrollService.getLastPositionForEventList(), behavior: 'smooth' });
        this.moveToPreviousScrollPosition = false;
      }
    }
  }

  initListSubscription() {
    if (!this.favoritesListSubs) {
      this.favoritesListSubs = this.favoritesService.getFavorites().subscribe(
        (favorites: Event[]) => {
          this.favoritesList = favorites;
          this.totalHits = favorites?.length || 0;
          this.loading = false;
        }
      );
    }
  }


}
