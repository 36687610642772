import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthService } from '../services/auth.service';
import { QuestionWarningComponent } from '../question-warning/question-warning.component';
import { QuestionPasswordComponent } from '../question-password/question-password.component';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { User, GoogleAuthProvider, FacebookAuthProvider, EmailAuthProvider } from '@angular/fire/auth';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  resetLinkSent: boolean = false;
  deleteAllData: FormControl = new FormControl();
  forceAuthentication: boolean = true;
  emailFormControl = new FormControl('', [Validators.email]);

  constructor(
    public translate: TranslateService,
    public authService: AuthService,
    public dialog: MatDialog,
    private meta: Meta,
    public snackBar: MatSnackBar) { }

  ngOnInit() {
    // Reached After redirect
    this.authService.getRedirectResult().then((result) => {
      if (typeof window !== 'undefined' && (window.location.hash === "#deleteAccount" || window.location.hash === "#deleteAccountAndData")) {
        try {
          this.forceAuthentication = false;
          this.deleteAccount(window.location.hash === "#deleteAccountAndData");
          window.location.hash = "";
        } catch (error) {
          this.snackBar.open(this.translate.instant("An error occurred while deleting your account"), null, {
            duration: 4000,
          })
        }
      }
    }).catch(error => {
      console.error(error);
      this.snackBar.open(this.translate.instant("An error occurred while deleting your account"), null, {
        duration: 4000,
      })
    });
    this.meta.addTag({ name: 'robots', content: 'noindex' });
  }

  getLoginType(credentials: string) {
    if (credentials === 'password') {
      return this.translate.instant("Email");
    }
    if (credentials === 'google.com') {
      return this.translate.instant("Google account");
    }
    if (credentials === 'facebook.com') {
      return this.translate.instant("Facebook account");
    }
  }

  deleteAccount(deleteData: boolean) {
    let userSubs = this.authService.user.subscribe((account: User) => {
      if (account) {
        if (this.forceAuthentication) {
          this.forceAuthentication = false;
          this.reauthenticateUserAndDeleteAccount(account);
        } else {
          if ((account && account.email) || this.emailFormControl.valid) {
            this.authService.gdprRequestDelete(account.uid, account.email || '', account.email || this.emailFormControl.value, deleteData)
              .then(() => {
                this.authService.signOut();
                this.snackBar.open(this.translate.instant("Your deletion request has been initiated"), null, {
                  duration: 4000,
                });
              })
              .catch((error) => {
                console.error(error);
                if (error.code === 'auth/requires-recent-login') {
                  //Reauthenticate the user
                  this.reauthenticateUserAndDeleteAccount(account);
                } else {
                  this.snackBar.open(this.translate.instant("An error occurred while deleting your account data"), null, {
                    duration: 4000,
                  });
                }
              });
          } else {
            if (!this.emailFormControl.valid) {
              this.emailFormControl.markAsTouched();
            }
            if (!account) {
              this.snackBar.open(this.translate.instant("You must be logged in to export your account data"), null, {
                duration: 4000,
              });
            }
          }
        }
      } else {
        this.snackBar.open(this.translate.instant("You must be logged in to delete your account"), null, {
          duration: 4000,
        });
      }
      userSubs.unsubscribe();
    });
  }

  warningDeleteAccount() {
    let dialogRef = this.dialog.open(
      QuestionWarningComponent, {
        width: '400px',
        data: {
          title: this.translate.instant("Delete your account"),
          question: this.translate.instant("Are you sure that you want to delete your account?")
        }
      });

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.deleteAccount(this.deleteAllData.value);
      }
    });
  }

  reauthenticateUserAndDeleteAccount(account: User) {
    let provider: any;
    const oauth: boolean = account.providerData[0].providerId !== 'password';

    if (oauth) {
      if (account.providerData[0].providerId === 'google.com') {
        provider = new GoogleAuthProvider();
        provider.addScope("https://www.googleapis.com/auth/userinfo.email");
      }
      if (account.providerData[0].providerId === 'facebook.com') {
        provider = new FacebookAuthProvider();
        provider.addScope("email");
      }
      window.location.hash = this.deleteAllData.value ? "deleteAccountAndData" : "deleteAccount";
      this.authService.reauthenticateWithRedirect(account, provider).then(
        () => { },
        (error) => {
          console.log(error);
          this.snackBar.open(this.translate.instant("An error occurred while deleting your account"), null, {
            duration: 4000,
          });
        }
      );
    } else if (account.providerData[0].providerId === 'password') {
      //Prompt password
      let dialogRef = this.dialog.open(
        QuestionPasswordComponent, {
          width: '400px',
        });
      dialogRef.afterClosed().subscribe(password => {
        if (password) {
          provider = EmailAuthProvider.credential(account.email, password);
          this.authService.reauthenticateWithCredential(account, provider).then(
            () => {
              this.authService.gdprRequestDelete(account.uid, account.email, account.email, this.deleteAllData.value)
                .then(() => {
                  this.authService.signOut();
                  this.snackBar.open(this.translate.instant("Your deletion request has been initiated"), null, {
                    duration: 4000,
                  });
                })
                .catch((error) => {
                  console.error(error);
                  if (error.code === 'auth/requires-recent-login') {
                    //Reauthenticate the user
                    this.reauthenticateUserAndDeleteAccount(account);
                  }
                });
            },
            (error) => {
              console.error(error);
              if (error.code === 'auth/wrong-password') {
                this.snackBar.open(this.translate.instant("Incorrect password. Please, try again."), null, {
                  duration: 4000,
                });
              } else {
                this.snackBar.open(this.translate.instant("An error occurred while deleting your account"), null, {
                  duration: 4000,
                });
              }
            }
          );
        } else {
          this.snackBar.open(this.translate.instant("You must introduce your password to delete your account"), null, {
            duration: 4000,
          });
        }
      });
    }
  }

  logOut() {
    this
      .authService
      .signOut()
      .then(() => {
        this.snackBar.open(this.translate.instant("Signing out successful"), null, {
          duration: 4000,
        });
      })
      .catch((error) => {
        console.error(error);
        this.snackBar.open(this.translate.instant("An error occurred while signing out"), null, {
          duration: 4000,
        });
      });
  }

  resetPassword(email: string) {
    this
      .authService
      .reset(email)
      .then(() => {
        this.snackBar.open(this.translate.instant("A reset link was sent to your email"), null, {
          duration: 4000,
        });
      })
      .catch((error) => {
        console.error(error);
        this.snackBar.open(this.translate.instant("An error occurred while resetting your password"), null, {
          duration: 4000,
        });
      });
  }

  exportData(): void {
    let userSubs = this.authService.user.subscribe((account: User) => {
      if ((account && account.email) || this.emailFormControl.valid) {
        this.authService.gdprRequestExport(account.uid, account.email || '', account.email || this.emailFormControl.value)
          .then(() => {
            this.emailFormControl.reset();
            this.snackBar.open(this.translate.instant("Your account data is being sent to your email address"), null, {
              duration: 4000,
            });
          })
          .catch((error) => {
            console.error(error);
            this.snackBar.open(this.translate.instant("An error occurred while exporting your account data"), null, {
              duration: 4000,
            });
          });
      } else {
        if (!this.emailFormControl.valid) {
          this.emailFormControl.markAsTouched();
        }
        if (!account) {
          this.snackBar.open(this.translate.instant("You must be logged in to export your account data"), null, {
            duration: 4000,
          });
        }
      }
      userSubs.unsubscribe();
    });
  }

}
