import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, Meta, SafeHtml } from '@angular/platform-browser';
import { AuthService } from '../services/auth.service';
import { CampaignsService } from '../services/campaigns.service';

import { Campaign } from '../models/campaign';
import { User } from '../models/user';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit {

  user: User;
  campaign: Campaign;
  campaignID: string;
  myHtml: SafeHtml;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private route: ActivatedRoute,
    private meta: Meta,
    private sanitizer: DomSanitizer,
    private campaignsService: CampaignsService,
    public authService: AuthService) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.setupUserInfo();
    }
    this.meta.addTag({ name: 'robots', content: 'noindex' });
  }

  setupUserInfo(): void {
    this.authService.getCurrentUserInfo().pipe(first()).subscribe(
      (user: User) => {
        if (user) {
          this.user = user;
          if (user?.roles?.admin) {
            this.route.paramMap.pipe(first()).subscribe(
              async (params: ParamMap) => {
                this.campaign = await this.campaignsService.getCampaign(params.get('campaignId'));
                this.myHtml = this.sanitizer.bypassSecurityTrustHtml(this.campaign.body);
              }
            );
          }
        }
      }
    )
  }
}
