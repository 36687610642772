// storage.service.ts
import { Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  private getFullKey(key: string): string {
    return `${environment.firebase.projectId}_${key}`;
  }

  get(key: string): any {
    if (isPlatformBrowser(this.platformId)) {
      try {
        const item = localStorage.getItem(this.getFullKey(key));
        if (!item) return null;
        
        try {
          return JSON.parse(item);
        } catch (e) {
          return item; // Return raw value if not valid JSON
        }
      } catch (e) {
        console.warn(`Error accessing localStorage for key ${key}:`, e);
        return null;
      }
    }
    return null;
  }

  set(key: string, value: any): void {
    if (isPlatformBrowser(this.platformId)) {
      try {
        const storageValue = typeof value === 'string' ? 
          value : 
          JSON.stringify(value);
        localStorage.setItem(this.getFullKey(key), storageValue);
      } catch (e) {
        console.warn(`Error setting localStorage for key ${key}:`, e);
      }
    }
  }

  remove(key: string): void {
    if (isPlatformBrowser(this.platformId)) {
      try {
        localStorage.removeItem(this.getFullKey(key));
      } catch (e) {
        console.warn(`Error removing localStorage for key ${key}:`, e);
      }
    }
  }
}