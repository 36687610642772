<div class="wide-wrapper">
    <div class="mat-elevation-z1 page">
        <div class="header-bookings-page">
            <h1 class="mat-h1" translate>Bookings</h1>
            <a mat-raised-button color="primary" routerLink="/bookings/new"><img class="icon-button"
                    src="assets/icons/add_circle.svg" /> <span>{{'Book tickets' | translate}}</span></a>
        </div>
        <div>
            <div class="events-top">
                <app-booking-event-selector class="events-filter" [eventFormControl]="eventSelectorControl"
                    (onEventsLoaded)="onEventsLoaded($event)" (onFilterChanged)="onEventSelectorFilterChanged($event)"
                    [filter]="eventSelectorFilter"></app-booking-event-selector>
                <mat-form-field appearance="outline" class="bookings-search">
                    <mat-label>{{'Filter bookings' | translate}}</mat-label>
                    <input matInput [formControl]="searchFieldControl" [placeholder]="'Ref, phone or name' | translate"
                        #filter>
                    <mat-hint *ngIf="!filter.value">{{'Filter bookings for these events' |
                        translate}}
                    </mat-hint>
                    <mat-hint align="end" *ngIf="searchFieldControl.value?.length > 0"><a class="blue"
                            (click)="searchFieldControl.reset()">{{'Clear' | translate}}</a>
                    </mat-hint>
                </mat-form-field>
            </div>

            <div *ngIf="searchFieldControl.value?.length > 0">
                <div *ngIf="searchingBookings" class="searching-text">{{'Searching in bookings' | translate}}...</div>
                <div *ngIf="!searchingBookings && !showSearchInBookingsError">
                    <div class="count-found">{{(bookingsSearchResults?.length || 0) | number:'':'nb-NO'}} {{'bookings
                        found' | translate}}
                    </div>
                    <div *ngIf="bookingsSearchResults?.length > 0" class="bookings-list">
                        <div class="bookings-header">
                            <div class="header-summary">
                                <div class="header-status">{{'Status' | translate}}</div>
                                <div class="header-booking-info">{{'Booking' | translate}}</div>
                                <div class="header-comments">{{'Comments' | translate}}</div>
                            </div>
                            <div class="header-meta">{{'User' | translate}}</div>
                        </div>
                        <div class="booking-row" *ngFor="let booking of bookingsSearchResults">
                            <div class="booking-summary" [ngClass]="{ selected: expandedBooking?.id == booking.id }"
                                (click)="toggleBookingDetails(booking)">
                                <div class="booking-data">
                                    <div class="booking-status">
                                        <div>{{booking.status | bookingStatus:translate.currentLang}}</div>
                                        <div>{{booking?.actions | lastActionStatus:translate.currentLang}}</div>
                                    </div>
                                    <div class="booking-info">
                                        <div>{{booking.customerName}} · {{booking.customerPhone | phone}} · <span
                                                *ngFor="let ticket of booking.tickets">{{
                                                ticket.numberTickets
                                                > 0 ?
                                                (ticket.numberTickets + ' x ' + getTicketType(ticket)) + ' ' : '
                                                '}}</span> · {{ booking.totalAmount |
                                            currency:'NOK':'symbol':'0.2-2':'nb'}}</div>
                                        <div>
                                            <a (click)="$event.stopPropagation()" class="filter"
                                                [routerLink]="['/bookings']"
                                                [queryParams]="{ eventId: booking.eventId, startDateSeconds: booking.startDate.seconds || booking.startDate._seconds, venueName: booking.venueName }">{{trim(booking.eventTitle,
                                                20)}} {{'@'}} {{trim(booking.venueName, 20)}} -
                                                {{booking.startDate | firestoreTimestamp |
                                                date:'short':'CET':translate.currentLang}}</a>
                                        </div>
                                    </div>
                                    <div class="booking-comments">{{booking.comments}}</div>
                                </div>
                                <div class="booking-metadata">
                                    <div>{{(booking.actions[0]?.timestamp ||
                                        booking.bookingDate) | firestoreTimestamp |
                                        date:'short':'CET':translate.currentLang }} {{'by' | translate}}
                                        {{utilsService.getAuthor(booking.actions[0]?.authorEmail ||
                                        booking.authorEmail)}}</div>
                                    <div>Ref.: {{booking.bookingNumber}}</div>
                                </div>
                            </div>
                            <div class="booking-details" *ngIf="expandedBooking?.id == booking.id">
                                <app-booking-log [booking]="booking"></app-booking-log>
                                <app-booking-edition [isAdmin]="true" [booking]="booking"></app-booking-edition>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="bookingsSearchResults?.length == 0">
                        {{'No bookings found for this criteria' | translate}}
                    </div>
                </div>
                <div *ngIf="!searchingBookings && showSearchInBookingsError">{{'Something went wrong' |
                    translate}}. {{'Please contact support' | translate}}.</div>
            </div>

            <div *ngIf="searchFieldControl.value == null || searchFieldControl.value?.length == 0">

                <div *ngIf="fetchingEvents" class="loading-data">
                    {{'Loading events' | translate}}...
                </div>

                <div *ngIf="!fetchingEvents" class="events">
                    <div>
                        <div class="count-found" *ngIf="events.length > 0">{{(events.length || 0) | number:'':'nb-NO'}}
                            {{'events found' |
                            translate}}</div>

                        <div class="event-row" *ngFor="let event of events">
                            <div class="event-summary"
                                [ngClass]="{ selected: expandedEvent?.id == event.id && expandedEvent.startDate.seconds == event.startDate.seconds && expandedEvent.venueObj.name == event.venueObj.name }"
                                (click)="toggleBookings(event)">
                                <div class="event-title">{{ event.title_nb }}</div>
                                <div class="event-details">
                                    <div class="event-detail">
                                        <div class="date"><strong>{{'Date' | translate}}:</strong> {{ event.startDate |
                                            firestoreTimestamp | date:'short':'CET':translate.currentLang }}</div>
                                            <div class="cancellation" [class.isOver]="utilsService.getCancellationDeadline(event) < now" >
                                                <strong>{{'Cancellation deadline' |
                                                translate}}:</strong> {{ utilsService.getCancellationDeadline(event) |
                                            date:'short':'CET':translate.currentLang }}</div>
                                        <div class="venue"><strong>{{'Venue' | translate}}:</strong> {{
                                            event.venueObj?.name}}
                                        </div>
                                    </div>
                                    <div class="event-detail">
                                        <div class="booking-count"><strong>{{'Available' | translate}}:</strong> {{
                                            event.availableTickets }}</div>
                                        <div class="booking-count"><strong>{{'Active' | translate}}:</strong> {{
                                            event.activeTickets }}</div>
                                        <div class="booking-count" *ngIf="event.bookingsStats"><strong>{{'Waiting list'
                                                |
                                                translate}}:</strong> {{
                                            event.bookingsStats.waitingListTickets }}</div>
                                    </div>
                                </div>
                            </div>
                            <div
                                *ngIf="expandedEvent?.id == event.id && expandedEvent.startDate.seconds == event.startDate.seconds && expandedEvent.venueObj.name == event.venueObj.name">
                                <div class="loading-data" *ngIf="fetchingBookings">{{'Loading bookings' | translate}}...
                                </div>
                                <div *ngIf="!fetchingBookings" class="bookings-list">
                                    <div class="event-bookings-actions">
                                        <div class="bookings-edition"
                                            *ngIf="!checkinMode && selection?.selected?.length > 0">
                                            <app-booking-edition [bookings]="selection.selected" isAdmin="true"
                                                (onExecutionDone)="selection.clear()"></app-booking-edition>
                                        </div>
                                        <div *ngIf="checkinMode">
                                            <app-in-door-check-in [event]="expandedEvent"></app-in-door-check-in>
                                        </div>
                                        <div class="bookings-selection">
                                            <div fxLayout="row" fxLayoutGap="10px">
                                                <div>{{(bookings.length || 0) | number:'':'nb-NO'}} {{'found' |
                                                    translate}}</div>
                                                <div *ngIf="selection?.selected?.length > 0">|</div>
                                                <div *ngIf="selection?.selected?.length > 0">
                                                    {{selection?.selected?.length}}
                                                    {{'selected' | translate}}</div>
                                                <div *ngIf="bookings.length > 0">|</div>
                                                <div *ngIf="bookings.length > 0">{{'Save check-in list' | translate}}
                                                    (<a class="blue" (click)="saveCheckinList('pdf')">{{'PDF' |
                                                        translate}}</a>
                                                    | <a class="blue" (click)="saveCheckinList('csv')">{{'spreadsheet' |
                                                        translate}}</a>)</div>
                                            </div>
                                            <mat-slide-toggle *ngIf="bookings.length > 0"
                                                (change)="toggleCheckinMode()">{{'Check-in mode' |
                                                translate}}</mat-slide-toggle>
                                        </div>
                                    </div>
                                    <div class="bookings-header" *ngIf="bookings.length > 0">
                                        <div class="header-summary">
                                            <div *ngIf="!checkinMode" class="header-checkbox">
                                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                                    [checked]="selection.hasValue() && isAllSelected()"
                                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                                                </mat-checkbox>
                                            </div>
                                            <div class="header-checkin" *ngIf="checkinMode">{{'Checkin' | translate}}
                                            </div>
                                            <div class="header-status">
                                                <mat-form-field>
                                                    <mat-select [formControl]="filterStatus" multiple>
                                                        <mat-select-trigger>
                                                            <mat-select-trigger>
                                                                <span
                                                                    *ngIf="filterStatus.value?.length == allStatus.length">{{'Status'
                                                                    | translate}} ({{'all' | translate}})</span>
                                                                <span
                                                                    *ngIf="filterStatus.value?.length != allStatus.length">{{'Status'
                                                                    | translate}}
                                                                    ({{filterStatus.value?.length}})</span>
                                                            </mat-select-trigger>
                                                        </mat-select-trigger>
                                                        <mat-option *ngFor="let status of allStatus"
                                                            [value]="status.id">{{status.name}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="header-customer">{{'Customer' | translate}}</div>
                                            <div class="header-tickets">{{'Tickets' | translate}}</div>
                                            <div class="header-comments">{{'Comments' | translate}}</div>
                                        </div>
                                        <div class="header-meta">{{'User' | translate}}</div>
                                    </div>
                                    <div class="booking-row" *ngFor="let booking of bookings">
                                        <div class="booking-summary"
                                            [ngClass]="{ selected: expandedBooking?.id == booking.id }"
                                            (click)="toggleBookingDetails(booking)">
                                            <div class="booking-data">
                                                <div *ngIf="!checkinMode" class="booking-checkbox">
                                                    <mat-checkbox (click)="$event.stopPropagation()"
                                                        (change)="$event ? selectBooking(booking) : null"
                                                        [checked]="selection.isSelected(booking)">
                                                    </mat-checkbox>
                                                </div>
                                                <div *ngIf="checkinMode" class="booking-checkin">
                                                    <div *ngIf="booking.status == 'active' || booking.status == 'attended' || booking.status == 'partially_attended' || booking.status == 'no_show'"
                                                        fxLayoutAlign="center center" fxLayoutGap="7px">
                                                        <button (click)="removeCheckedIn($event, booking)"
                                                            [ngClass]="{ 'disabled': getCheckedIn(booking) == 0}"
                                                            type="button"
                                                            class="background-accent checkin-button">-</button>
                                                        <div fxFlex="50px" class="text-center">{{getCheckedIn(booking)}}
                                                            /
                                                            {{getNumTickets(booking)}}</div>
                                                        <button (click)="addCheckedIn($event, booking)"
                                                            [ngClass]="{ 'disabled': getCheckedIn(booking) == getNumTickets(booking)}"
                                                            type="button"
                                                            class="background-accent checkin-button">+</button>
                                                    </div>
                                                </div>
                                                <div class="booking-status">
                                                    <div>{{booking.status | bookingStatus:translate.currentLang}}</div>
                                                    <div>{{booking?.actions | lastActionStatus:translate.currentLang}}
                                                    </div>
                                                </div>
                                                <div class="booking-customer">
                                                    <div>{{booking.customerName}}</div>
                                                    <div>{{booking.customerPhone | phone}}</div>
                                                </div>
                                                <div class="booking-tickets">
                                                    <div><span *ngFor="let ticket of booking.tickets">{{
                                                            ticket.numberTickets
                                                            > 0 ?
                                                            (ticket.numberTickets + ' x ' + getTicketType(ticket)) + ' '
                                                            : '
                                                            '}}</span> </div>
                                                    <div>{{ booking.totalAmount |
                                                        currency:'NOK':'symbol':'0.2-2':'nb'}}</div>
                                                </div>
                                                <div class="booking-comments">{{booking.comments}}</div>
                                            </div>
                                            <div class="booking-metadata">
                                                <div>{{(booking.actions[0]?.timestamp ||
                                                    booking.bookingDate) | firestoreTimestamp |
                                                    date:'short':'CET':translate.currentLang }} {{'by' | translate}}
                                                    {{utilsService.getAuthor(booking.actions[0]?.authorEmail ||
                                                    booking.authorEmail)}}</div>
                                                <div>Ref.: {{booking.bookingNumber}}</div>
                                            </div>
                                        </div>
                                        <div class="booking-details" *ngIf="expandedBooking?.id == booking.id">
                                            <app-booking-log [booking]="booking"></app-booking-log>
                                            <app-booking-edition [isAdmin]="true"
                                                [booking]="booking"></app-booking-edition>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="margin-top" *ngIf="events?.length > 0">
                            <app-bookings-stats [events]="events"></app-bookings-stats>
                        </div>
                        <div *ngIf="events?.length == 0">{{'No events found with this criteria' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>