<div class="tile" [ngClass]="{'vmfest-font' : isVMFest}" >
    <div class="top">
        <div class="over-date" fxLayout="column">
            <div class="month">{{getMonth(event.startDate)}}</div>
            <div class="day">{{getDay(event.startDate)}}</div>
            <div class="separator" *ngIf="maybeGetUntilDate()"></div>
            <div class="until">{{maybeGetUntilDate()}}</div>
        </div>
        <div class="over-favorite" fxLayout="row" fxLayoutAlign="center center">
            <button mat-icon-button (click)="toggleFavorite(event.event_slug)">
                <mat-icon>{{ isFavorite ? 'favorite' : 'favorite_outlined'}}</mat-icon>
            </button>
        </div>
        <a [routerLink]="[isVMFest ? '/vm-fest': '/', 'event', event.event_slug]"><img [src]="event.images[0]?.urlLarge" /></a>
    </div>
    <div class="bottom">
        <a class="title" [routerLink]="[isVMFest ? '/vm-fest': '/', 'event', event.event_slug]">{{utilsService.getTitle(event)}}</a>
        <div class="date">{{getDate(event.startDate)}} {{ event.repetitions?.length > 0 ?
            "(+" + event.repetitions?.length + ")" : '' }}</div>
        <div class="venue">{{event.venue?.name}}</div>
        <a class="read-more" [routerLink]="[isVMFest ? '/vm-fest': '/', 'event', event.event_slug]">{{'Read more' | translate}}</a>
    </div>
</div>