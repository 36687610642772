import { Component, OnInit, Input, Output, EventEmitter, PLATFORM_ID, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { EventService } from '../services/event.service';
import { RepetitionService } from '../services/repetition.service';
import { CategoryService } from '../services/category.service';
import { UtilsService } from '../services/utils.service';
import { environment } from '../../environments/environment';

import { Event } from '../models/event';

import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { first, map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { EventRepetition } from '../models/event-repetition';
import { SeoService } from '../services/seo.service';

@Component({
  selector: 'app-event-list-item',
  templateUrl: './event-list-item.component.html',
  styleUrls: ['./event-list-item.component.scss']
})
export class EventListItemComponent implements OnInit {

  @Input()
  event: Event = null;
  @Input()
  last: boolean = false;
  @Input()
  indexInList: number;

  @Output()
  afterViewInit = new EventEmitter();

  schema = {};

  childrenEvents: Event[] = [];
  nextRepetitions: EventRepetition[] = [];
  totalChildrenEvents: number = 0;
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  environment: any;
  productLD: any;
  hasImages: boolean = false;

  constructor(
    public utilsService: UtilsService,
    private seoService: SeoService,
    public categoryService: CategoryService,
    public translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: any,
    private repetitionService: RepetitionService,
    private eventService: EventService) {
    this.environment = environment;
  }

  ngOnInit() {
    this.productLD = this.seoService.getEventSchema(this.event);
    this.setupNextRepetitions();
    if (isPlatformBrowser(this.platformId)) {
      const img = this.event.images && this.event.images[0]?.urlLarge;
      this.hasImages = img != null && img != undefined;
      if (this.event.type === 'super-event') {
        this.isLoading$.next(true);
        this.eventService.getChildrenEvents(this.event.id).pipe(
          first(),
          map((results: any) => {
            if (results) {
              this.totalChildrenEvents = results.data?.events?.totalCount || 0;
              return results;
            }
            return null;
          }),
          this.eventService.getTransformGraphQLEventsWithPagination()
        ).subscribe(
          (festEvents) => {
            this.isLoading$.next(false);
            this.childrenEvents = festEvents;
          }
        );
      }
    }
  }

  ngAfterViewInit() {
    this.afterViewInit.emit();
  }

  getMomentStartDate() {
    return moment(this.event.startDate.seconds * 1000);
  }

  getMomentEndDate() {
    return moment(this.event.endDate.seconds * 1000);
  }

  displayStartDate() {
    let startDate = moment(this.event.startDate.seconds * 1000);
    return startDate.format(this.utilsService.getDateFormat());
  }

  displayEndDate() {
    let endDate = moment(this.event.endDate.seconds * 1000);
    return endDate.format(this.utilsService.getDateFormat());
  }

  displayTime() {
    let startDate = moment(this.event.startDate.seconds * 1000);

    let displayedDate = startDate.format(this.utilsService.getTimeFormat());
    if (this.event.duration != 0 && this.event.startDate?.seconds != this.event.endDate?.seconds) {
      let endTime = moment(this.event.endDate.seconds * 1000);
      displayedDate += " - " + endTime.format(this.utilsService.getTimeFormat());
    }
    return displayedDate;
  }

  isSuperEvent(): boolean {
    return this.event.type != 'super-event'
  }

  getMaxPrice(): number {
    return Math.max.apply(Math, (this.event.prices || []).map((o) => o.price ));
  }

  getMinPrice(): number {
    return Math.min.apply(Math, (this.event.prices || []).map((o) => o.price ));
  }

  getStartDate(rep: EventRepetition) {
    const startDate = moment(rep.startDate).isValid() ? moment(rep.startDate) : moment(rep.startDate.seconds * 1000);
    return startDate.format(this.utilsService.getDateFormat()) + ((rep.startTime && " @ " + this.utilsService.getLocalizedHour(rep.startTime)) || '');
  }

  setupNextRepetitions() {
    this.nextRepetitions = this.repetitionService.getNextRepetitions(this.event, 0);
  }
}
