<div class="wrapper">
  <div class="mat-elevation-z1 page">
    <h1 class="mat-h1" translate>Feed to your website</h1>

    <form [formGroup]="feedForm">
      <mat-tab-group>
        <mat-tab label="GraphQL API">
          <iframe style="height: 2500px; width: 100%;"  frameborder="0" [src]="graphQLURLSafe"></iframe>
        </mat-tab>
      </mat-tab-group>
    </form>
  </div>
</div>